<template>
  <Layout>
    <div v-if="isBusy" style="text-align: center">
      <strong>Loading...</strong>
    </div>
    <div v-else>
      <div class="row" v-if="advertiser">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12"><strong>Advertiser Info</strong>
                  <b-button variant="primary" size="sm" class="float-right" @click="$bvModal.show(`update-advertiser-modal`)">
        Edit
      </b-button>
                </div>
              </div>

              <div class="row">
                <div class="col-4">Mobile</div>
                <div class="col-8">
                  {{ advertiser.country_code }}- {{ advertiser.phone_number }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">Email</div>
                <div class="col-8">
                  {{ advertiser.email }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">Email Verified</div>
                <div class="col-8" v-if="advertiser">
                  {{ advertiser.email_verfied_at ? "Yes" : "No" }}
                </div>
              </div>

              <div class="row">
                <div class="col-4">Account Created on</div>
                <div class="col-8">
                  {{ moment(advertiser.createdAt).format("LLL") }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">Login</div>
                <div class="col-8">
                  <a href="#" @click="loginAsAdvertiser" >Click Here
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1">
            <b-tabs content-class="mt-3">
              <b-tab title="Company Details" active>

                <div class="d-flex justify-content-end my-2">

                  <add-company-modal v-on:add="companyAdded" :id="advertiser._id" :data="advertiser._id" />
                </div>

                <div v-if="advertiser.company.length === 0" class="text-center">
                  No Company
                </div>
                <div class="row" v-else>
                  <div class="col-md-6" v-for="company in advertiser.company" :key="company.id">
                    <div class="card p-2">
                      <edit-company-modal v-on:edit="companyEdited" :data="company" />
                      <div>
                        <strong>{{ company.name }}</strong> ( License No. :
                        {{ company.trade_license_number }})
                      </div>
                      <a :href="company.trade_license_doc" class="trade-licence-download" target="_blank">Trade
                        License</a><br />
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Ads">
                <div v-if="loadingAds">
                  <strong>Loading...</strong>
                </div>
                <div v-else id="profile-ads">
                  <div v-if="ads.length === 0" class="text-center">No Ads</div>
                  <div v-else>
                    <div class="row">
                      <div class="col-md-4" v-for="ad in filterData" :key="ad._id">
                        <div :class="
                          ad.payment_done ? 'payment-success' : 'payment-due'
                        " class="card p-2" @click="adSelected(ad)">
                          <div>
                            <strong>Payment Done</strong> :
                            {{ ad.payment_done ? "Yes" : "No" }}
                          </div>
                          <div>
                            <strong>Sticker Type</strong> :
                            {{ ad.sticker_type }}
                          </div>
                          <div>
                            <strong>Plan Price</strong> :
                            {{ ad.plan_price }} SAR
                          </div>
                          <div>
                            <strong>Drivers Required</strong> :
                            {{ ad.no_of_drivers_required }}
                          </div>
                          <div>
                            <strong>Drivers Assigned</strong> :
                            {{ ad.no_of_drivers_assigned }}
                          </div>
                          <div>
                            <strong>Start within</strong> :
                            {{ moment(ad.start_within).format("LLL") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-pagination v-model="currentPage" @change="changePage" :total-rows="rows" :per-page="perPage"
                      aria-controls="my-table"></b-pagination>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>


      <b-modal ref="updateAdvertiserModal" :id="`update-advertiser-modal`" title="Update Advertiser" hide-footer>
        <form @submit.prevent="updateAdvertiser">
          <div class="form-group">
            <label for="">Email</label>
            <input v-model="form.email" type="email" class="form-control" name="email"
               placeholder="Email here" />
            
          </div>
          <div class="form-group">
            <label for="">Phone Number</label>
            <input v-model="form.phone_number" type="text" class="form-control" name="phone_number" placeholder="License number here" />
            
          </div>

          <div class="form-group mb-0 mt-2">
            <div class="d-flex justify-content-end">
              <b-button type="submit" variant="primary" size="sm">
                <span v-if="submitting">Submitting...</span>
                <span v-else> Submit </span>
              </b-button>
            </div>
          </div>
        </form>
      </b-modal>


    </div>
  </Layout>
</template>
<style>
.vehicleinfo {
  cursor: pointer;
}
</style>
<script>
import Layout from "../../layouts/main";
import { advertiser } from "@/config/api/advertiser";
import AddCompanyModal from "./add-company-modal.vue";
import EditCompanyModal from "./edit-company-modal.vue";
export default {
  props: ["id"],
  components: {
    Layout,
    AddCompanyModal,
    EditCompanyModal,
  },
  data() {
    return {
      isBusy: false,
      loadingAds: false,
      advertiser: undefined,
      currentPage: 1,
      ads: [],
      rows: 0,
      perPage: 12,
      filterData: [],
      form: {
        email: "",
        phone_number: "",
      },
      submitting: false,
      submit: false,
    };
  },
  mounted() {
    this.loadAdvertiserProfile();
    this.loadAds();
  },
  methods: {
    loadAdvertiserProfile() {
      this.isBusy = true;
      const api = advertiser.getById;
      api.id = this.id;
      this.generateAPI(api)
        .then((res) => {
          this.advertiser = res.data.advertiser[0];
          this.form.email = res.data.advertiser[0].email;
          this.form.phone_number = res.data.advertiser[0].phone_number;

        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    updateAdvertiser() {
      this.submit = true;
        const api = advertiser.editAdvertiser;
        api.data = this.form;
        api.id = this.id;
        this.submitting = true;
        this.generateAPI(api)
          .then((res) => {
            this.$bvToast.toast("Advertiser updated successfully!", {
              title: "Update!",
              variant: "success",
              solid: true,
            });
            console.log(res.data);
            this.$bvModal.hide("update-advertiser-modal");
            this.loadAdvertiserProfile();
          })
          .catch((err) => {
            this.$bvToast.toast(
              `Advertiser is not updated, ${err.response.data.error}!`,
              {
                title: "Update",
                variant: "danger",
                solid: true,
              }
            );
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    loginAsAdvertiser() {
      this.isBusy = true;
      const api = advertiser.loginById;
      api.id = this.id;
      this.generateAPI(api)
        .then((res) => {
          window.open(`https://advertiser.b-seenapp.com/auth/login?token=${res.data.token}`, '_blank');
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    loadAds() {
      this.isBusy = true;
      const api = advertiser.loadAds;
      api.params = {
        user_id: this.id,
      };
      this.loadingAds = true;
      this.generateAPI(api)
        .then((res) => {
          this.ads = res.data.campaigns;
          this.filterData = this.ads.slice(0, 12);
          this.rows = this.ads.length;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingAds = false;
        });
    },
    adSelected(row) {
      this.$store.dispatch("campaign/selectCampaign", row);
      this.$router.push({ name: "CampaignsDetails", params: { id: row._id } });
    },
    changePage(page) {
      const startIndex = this.perPage * (page - 1);
      const endIndex = startIndex + 12;
      this.filterData = this.ads.slice(startIndex, endIndex);
      console.log("Current page", page);
    },
    companyAdded(data) {
      if (this.advertiser) {
        this.advertiser.company.push(data);
      }
    },
    companyEdited(data) {
      this.advertiser.company = this.advertiser.company.map((item) => {
        return item._id === data._id ? data : item;
      });
    },
  },
};
</script>

<style>
.spaceDetails {
  justify-content: space-between;
}

.trade-licence-download {
  background-color: black;
  color: white;
  padding: 2px 6px;
  margin: 8px;
  border-radius: 4px;
  font-size: 12px;
  max-width: 88px;
}

.trade-licence-download:hover {
  color: gray;
}

#profile-ads .card {
  box-shadow: 0px 0px 5px 0px rgb(118 118 118 / 75%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(118 118 118 / 75%);
  -moz-box-shadow: 0px 0px 5px 0px rgb(118 118 118 / 75%);
  cursor: pointer;
}

.payment-due {
  background-color: #9d0000;
  color: white;
}

.payment-success {
  background-color: #143e14;
  color: white;
}
</style>
